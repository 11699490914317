import AppImage from 'components/app-image/AppImage';
import React from 'react';

const size = '100px';
export const pricings = [
  {
    name: 'Free',
    icon: <AppImage.Trial width={size} height={size} />,
    pricing: '0',
    decimal: '.00',
    caption: '',
    isHighlight: false,
    main: [
      {
        id: 1,
        text: '30 minutes private consultation',
      },
      {
        id: 2,
        text: "Assess your child's level",
      },
      {
        id: 3,
        text: 'Personalized teacher matching',
      },
      {
        id: 4,
        text: 'Free 30 SG math questions in Practicle Portal',
      },
    ],
    action: 'Book your trial today',
    actionLink: '',
  },
  {
    name: '1-to-1',
    icon: <AppImage.Solo width={'75px'} height={size} />,
    pricing: '260',
    decimal: '/mo',
    caption: 'Popular',
    isHighlight: true,
    main: [
      {
        id: 1,
        text: 'Complete SG math syllabus',
      },
      {
        id: 2,
        text: 'Biligual teaching',
      },
      {
        id: 3,
        text: '4 lessons per month',
      },
      {
        id: 4,
        text: 'Unique learning experience',
      },
      {
        id: 5,
        text: 'Unlimited questions in Portal',
      },
    ],
    secondary: [
      {
        id: 1,
        text: '1-to-1 teaching',
      },
      {
        id: 2,
        text: 'Constant interaction with teacher',
      },
      {
        id: 3,
        text: 'Flexible replacement classes',
      },
      {
        id: 4,
        text: 'Personal speed of progress',
      },
      {
        id: 5,
        text: 'Tailored learning and communication style',
      },
    ],
    action: 'Sign Up Now',
    actionLink: '',
  },
  {
    name: 'Peer Learning',
    icon: <AppImage.Group width={size} height={size} />,
    pricing: '160',
    decimal: '/mo',
    caption: '',
    isHighlight: false,
    main: [
      {
        id: 1,
        text: 'Complete SG math syllabus',
      },
      {
        id: 2,
        text: 'Biligual teaching',
      },
      {
        id: 3,
        text: '4 lessons per month',
      },
      {
        id: 4,
        text: 'Unique learning experience',
      },
      {
        id: 5,
        text: 'Unlimited questions in Portal',
      },
    ],
    secondary: [
      {
        id: 1,
        text: 'Maximum 4 peers in a class',
      },
      {
        id: 2,
        text: 'High quality of peers interaction',
      },
    ],
    action: 'Sign Up Now',
    actionLink: '',
  },
];
